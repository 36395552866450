<template>
  <div>
    <Header />
    <div class="body">
      <!-- titlebar -->
      <div id="titlebar">
        <div class="container">
          <!-- breadcrumbs -->
          <v-breadcrumbs :items="breadcrumbs" class="pa-1 pt-3 breadcrumb">
            <template v-slot:divider>
              <img
                alt=""
                src="@/assets/img/svgs/fi-br-angle-small-down.svg"
                loading="lazy"
              />
            </template>
          </v-breadcrumbs>
          <nav id="contactButtons" class="z-index__9">
            <div class="contactContainer">
              <div
                @click="contact(card.type)"
                v-for="(card, i) in contactCards"
                :key="i"
                :class="{ disabled: !isDisbled }"
                class="contactCard pl-4"
              >
                <div class="contactCardLogo">
                  <img :src="card.icon" alt="" loading="lazy" />
                </div>
                <div class="contactCardContent">
                  <h1 class="title">
                    {{ card.title }}
                  </h1>
                  <p class="desc">
                    {{ card.desc }}
                  </p>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>

      <!-- Container -->
      <div class="container actions">
        <div v-if="false" class="box-1 right">
          <div class="title">
            <p class="titleContent">المساعدات السابقه</p>
          </div>
          <div content-class="rightTabs">
            <b-tab class="step">
              <div class="container">
                <div class="content">
                  <p>لا يوجد شكاوي سابقه,</p>
                  <a href="" @click.prevent="loginPageRoute"
                    >يرجي تسجيل الدخول أولا</a
                  >
                </div>
              </div>
            </b-tab>
            <b-tab>
              <div v-if="lastComplains.length > 0">
                <div
                  class="complainContainer mt-5"
                  v-for="(complain, i) in lastComplains"
                  :key="i"
                >
                  <div class="logo ml-2 mr-4">
                    <img
                      :src="
                        complain.hectarType == 'hectar'
                          ? hectarLogo
                          : hectarPlusLogo
                      "
                      alt=""
                      loading="lazy"
                    />
                  </div>
                  <div class="content">
                    <h1>مركز المساعده</h1>
                    <p>{{ complain.content }}</p>
                  </div>
                </div>
              </div>
              <div v-else class="container">
                <div class="content">
                  <p>لا يوجد شكاوي سابقه,</p>
                </div>
              </div>
            </b-tab>
          </div>
        </div>
        <div class="box-2">
          <div class="title">
            <div class="titleLogo">
              <img src="@/assets/img/svgs/hectar.svg" alt="" loading="lazy" />
            </div>
            <p class="titleContent">مركز المساعده</p>
          </div>
          <div class="leftTabs" id="tab">
            <!-- Bootstrap Vue -->
            <div v-if="step" class="step1">
              <div class="logoContainer">
                <img
                  src="@/assets/img/svgs/hectar2X.svg"
                  alt=""
                  loading="lazy"
                />
                <h1>مركز المساعده</h1>
                <div v-if="firstTime">
                  <p>
                    أهلا ومرحبا بك, سعيدين لتواصلك معنا في مركز مساعده هكتار,
                  </p>
                  <button class="start" @click="startClick">ابدأ الأن</button>
                </div>
                <div v-else>
                  <p>“شكرا لك، ونتمنى نكون قد قمنا بخدمتك بالطريقة الأمثل“</p>
                  <button class="start" @click="startClick">
                    ابدأ من جديد
                  </button>
                </div>
              </div>
            </div>
            <div v-else class="step2" id="step2">
              <div v-if="counter >= 1" class="chatContainer mt-5">
                <div class="logo mr-4">
                  <img :src="hectarLogo" alt="" loading="lazy" />
                </div>
                <div class="content">
                  <p>
                    أهلا ومرحبا بيك {{ firstName }} , أخبرنا أين تواجه مشكلتك ؟
                  </p>
                  <div class="chatInnerContainer">
                    <button
                      :disabled="counterClick < 1 ? false : true"
                      @click="complainCreation('hectar', 'hectarType')"
                      class="hectar"
                    >
                      <img
                        src="@/assets/img/svgs/hectarChoice.svg"
                        alt=""
                        loading="lazy"
                      />
                    </button>
                    <button
                      :disabled="counterClick < 1 ? false : true"
                      @click="complainCreation('hectarPlus', 'hectarType')"
                      class="hectar+"
                    >
                      <img
                        src="@/assets/img/svgs/hectarChoicePlus.svg"
                        alt=""
                        loading="lazy"
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-if="complain.hectarType"
                class="chatContainer left mt-5 ml-5 mb-4"
              >
                <div class="content">
                  <div class="chatInnerContainer">
                    <p>
                      {{
                        complain.hectarType == 'hectar'
                          ? 'تطبيق هكتار'
                          : 'تطبيق هكتار بلس'
                      }}
                    </p>
                  </div>
                </div>
                <div class="logo">
                  <h6 v-if="userName" class="substringName ma-0">
                    {{ userName ? $subString(userName) : '' }}
                  </h6>
                </div>
              </div>
              <div
                v-if="showComplainType && counter >= 2"
                class="chatContainer bottom"
              >
                <div class="logo mr-4">
                  <img :src="hectarLogo" alt="" loading="lazy" />
                </div>
                <div class="content">
                  <p>ما هو القسم الذي تواجه فيه المشكله ؟</p>
                  <div class="chatInnerContainer">
                    <button
                      class="py-4"
                      v-for="(department, i) in departments"
                      :key="i"
                      :disabled="counterClick < 2 ? false : true"
                      @click="complainCreation(department, 'type')"
                    >
                      <img :src="department.icon" alt="" loading="lazy" />
                      <p class="mt-2 text-center ma-0">
                        {{ department.title }}
                      </p>
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-if="complain.department"
                class="chatContainer left mt-5 ml-5 mb-4"
              >
                <div class="content">
                  <div class="chatInnerContainer">
                    <p>
                      {{ complain.department }}
                    </p>
                  </div>
                </div>
                <div class="logo">
                  <h6 v-if="userName" class="substringName ma-0">
                    {{ userName ? $subString(userName) : '' }}
                  </h6>
                </div>
              </div>
              <div
                v-if="showExistedComplains && counter >= 3"
                class="chatContainer problems mt-5"
              >
                <div class="logo mr-4">
                  <img :src="hectarLogo" alt="" loading="lazy" />
                </div>
                <div class="content">
                  <p>هل المشكله التي تواجها ضمن هذه المشاكل ؟</p>
                  <div class="problemsList">
                    <ul>
                      <li v-for="(problem, i) in problems" :key="i">
                        <button
                          :disabled="counterClick < 3 ? false : true"
                          @click="complainCreation(problem, 'desc')"
                        >
                          {{ problem.question }}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                v-if="question.length"
                class="chatContainer left mt-5 ml-5 mb-4"
              >
                <div class="content">
                  <div class="chatInnerContainer">
                    <p>
                      {{ question }}
                    </p>
                  </div>
                </div>
                <div class="logo">
                  <h6 v-if="userName" class="substringName ma-0">
                    {{ userName ? $subString(userName) : '' }}
                  </h6>
                </div>
              </div>
              <div
                v-if="startAgain && counter >= 4 && answer != 'مشكله أخري'"
                class="chatContainer answer mt-5"
              >
                <div class="logo mr-4">
                  <img :src="hectarLogo" alt="" loading="lazy" />
                </div>
                <div class="content">
                  <p>
                    {{ answer }}
                  </p>
                </div>
              </div>
              <div
                v-if="startAgain && counter >= 4"
                class="chatContainer problems mt-5"
              >
                <div class="logo mr-4">
                  <img :src="hectarLogo" alt="" loading="lazy" />
                </div>
                <div v-if="answer == 'مشكله أخري'" class="content">
                  <p>
                    الرجاء تعبئة النموذج التالي وارساله لنا لمتابعة وحل المشكلة
                  </p>
                  <form>
                    <p v-if="formCheck">* من فضلك املأ الحقول بشكل صحيح</p>
                    <input
                      :disabled="inputState"
                      v-model="complain.title"
                      type="text"
                      placeholder="عنوان المشكله"
                    />
                    <textarea
                      :disabled="inputState"
                      v-model="complain.desc"
                      rows="10"
                      placeholder="وصف المشكله ...."
                    ></textarea>
                    <button
                      :disabled="inputState"
                      class="ml-5 cancel"
                      @click.prevent="resetComplain(true)"
                    >
                      إلغاء
                    </button>
                    <button :disabled="inputState" @click.prevent="onSubmit()">
                      إرسال
                    </button>
                  </form>
                </div>
              </div>
              <div
                v-if="
                  startAgain &&
                  counter >= 4 &&
                  (answer != 'مشكله أخري' ||
                    (answer == 'مشكله أخري' && submit == true))
                "
                class="chatContainer problems mt-5"
              >
                <div class="logo mr-4">
                  <img :src="hectarLogo" alt="" loading="lazy" />
                </div>
                <div class="content">
                  <p v-if="inputState">لقد تم تسجيل مشكلتك</p>
                  <p><b>- هل لديك أسئله أخري ؟ </b></p>
                  <div class="yesOrNo">
                    <button @click="resetComplain(true)">إبدأ من جديد</button>
                    <button @click="resetComplain(false)">
                      نعم, اعرض لي الأقسام
                    </button>
                    <button @click="backToStartPage()">لا, شكراً</button>
                  </div>
                </div>
              </div>
              <div v-if="typing" class="typeing mt-5">
                <div class="logo mr-4">
                  <img :src="hectarLogo" alt="" loading="lazy" />
                </div>
                <div class="content">
                  <div class="balls">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Container / End -->
    </div>
  </div>
</template>

<script>
import Header from '@/components/header/helpCenterHeader.vue'
export default {
  components: { Header },
  data() {
    return {
      isDisbled: false,
      inputState: false,
      step: true,
      typing: true,
      counter: 0,
      counterClick: 0,
      showComplainType: false,
      showExistedComplains: false,
      startAgain: false,
      complain: {},
      question: '',
      answer: '',
      formCheck: false,
      hectarInternationalPhoneNumber: '966 50 009 2515',
      loggedIn: '',
      rightTabIndex: 0,
      leftTabIndex: 0,
      firstTime: true,
      hectarLogo: require('@/assets/img/svgs/hectar.svg'),
      hectarPlusLogo: require('@/assets/img/svgs/hectar.svg'),
      contactCards: [
        {
          title: 'تواصل واتس',
          icon: require('@/assets/img/svgs/WhatsApp.svg'),
          desc: 'تواصل معنا خلال 9 ص إلي 5 م',
          type: 'whatsapp'
        }
      ],
      lastComplains: [
        {
          hectarType: 'hectar',
          content: 'تطبيق هكتار, رسائل العملاء تطبيق هكتار'
        },
        {
          hectarType: 'hectar+',
          content: 'تطبيق هكتار, رسائل العملاء تطبيق هكتار'
        }
      ],
      problems: [
        {
          question: 'مشكله أخري',
          answer: 'مشكله أخري'
        }
      ],
      departments: [],
      hectarDeps: [
        {
          title: 'التسجيل وإدارة الملف الشخصي',
          icon: require('@/assets/img/svgs/Hectar icon/user.svg'),
          problems: [
            {
              question: 'ماذا يعني عدم التمكن للدخول لحسابي بسبب وقف الحساب ؟',
              answer:
                'يعني ذلك وجود خطأ بسبب كتابة اسم المستخدم أو الرقم السري او وجود خطأ قانوني بعدم اتباع شروط واحكام وسياسات التسجيل، و في هذه الحالات يتم اغلاق الحساب لحين تصحيح اي من الاسباب المذكورة'
            },
            {
              question: 'كيف يمكنني تفعيل حسابي في حالة كان حسابي موقوفا ؟',
              answer:
                'يمكنك التواصل معنا عن طريق آلبة  والواتساب المتاحة في مركز المساعدة، وبعد التحقق والاستبيان من ملكية الحساب وعدم وجود اي متعلقات قانونية على الحساب سيتم إعادة فتح الحساب مرة اخري'
            }
          ]
        },
        {
          title: 'العقارات',
          icon: require('@/assets/img/svgs/Hectar icon/r-building.svg'),
          problems: [
            {
              question:
                'ماهي كيفية أو طريقة إضافة إعلان عقاري في منصة هكتار أو تطبيقات هكتار؟',
              answer:
                'يمكن إضافة إعلان عقاري من خلال إختيار إضافة عقار ومن ثم إتباع خطوات نموذج الإضافة ومن ثم نشر العقار'
            },
            {
              question:
                'ماهو النطاق الجغرافي الذي يتم فيه عرض العقارات المدرجة ؟',
              answer:
                'يكون موقع العقار المدرج في الخريطة مطابق للموقع الذي تم إختياره من قبل معلن العقار عند إضافة العقار'
            },
            {
              question:
                'كم يستغرق إضافة العقار المدرج لقائمة العقارات المتاحة للعرض بعض الإنتهاء من عملية "إضافة عقار" ؟ ',
              answer:
                'يكون العقار الذي تمت إضافته من قبل معلن عقار في منصة هكتار أو تطبيق هكتار متاحا للعرض من قبل المستخدمين في منصة هكتار و تطبيق هكتار فور الإنتهاء من إضافتة'
            },
            {
              question:
                'ماهي المدة التي يظل فيها العقار المدرج متاحا للعرض في منصة وتطبيق هكتار ؟',
              answer:
                'يظل العقار في حالة نشط ومتاحا للعرض في قائمة البحث والخريطة لمدة ٣٠ يوما من تاريخ ووقت إضافته لأول مرة، وتجدد هذه المدة كلما تم تعديل معلومات العقار أو تحديثه'
            },
            {
              question:
                'كيف يمكن إدارة العقارات المضافة عن طريق منصة أو تطبيق هكتار ؟',
              answer:
                'يتمكن المستخدم من إدارة العقارات المفعلة الخاصة به من خلال   إختيار شاشة “عقاراتي الخاصة بالمستخدم'
            },
            {
              question:
                'ماهي العمليات التي يتمكن المستخدم من خلال عقاراتي من تنفيذها ؟',
              answer:
                'يتمكن المستخدم من خلال خيار “عقاراتي” من عرض العقارات الخاصة به وبياناتها ، معرفة كمال البيانات من خلال المؤشر كمال البيانات ، وايضا تعديل معلومات العقار أو تحديث العقار أو حذفه نهائيا'
            }
          ]
        },
        {
          title: ' الجهات العقارية ',
          icon: require('@/assets/img/svgs/Hectar icon/house.svg'),
          problems: [
            {
              question: 'ماهي طرق عرض الجهات العقارية المتاحة في هكتار ؟',
              answer:
                'يمكن عرض الجهات العقارية المتاحة في هكتار عن طريق الخريطة والتي تعرض الجهات العقارية بناءا على موقعها الجغرافي، أو القائمة التي تحتوى على معلومات الجهات العقارية'
            },
            {
              question:
                'ماهو النطاق الجغرافي الذي يتم فيه عرض الجهات العقارية المدرجة ؟',
              answer:
                'يكون موقع الجهة العقارية المدرج في الخريطة مطابق للموقع الذي تم إختياره من قبل الجهة العقارية عند التسجيل أو تحديث بيانات الحساب'
            },
            {
              question: 'ماهي معلومات الجهات العقارية المتاحة ؟',
              answer:
                'يكون العقار الذي تمت إضافته من قبل معلن عقار في منصة هكتار أو تطبيق هكتار متاحا للعرض من قبل المستخدمين في منصة هكتار و تطبيق هكتار فور الإنتهاء من إضافتة'
            }
          ]
        },
        {
          title: 'البحث والعرض',
          icon: require('@/assets/img/svgs/Hectar icon/search.svg'),
          problems: [
            {
              question: 'ماهي طرق البحث عن الإعلانات العقارية ؟',
              answer:
                'البحث عن الإعلانات العقارية بشتى أنواعها يتم عن طريق تحديد منطقة محددة للبحث في الخريطة ومن ثم عرض العقارات المتاحة فيها من خلال الخريطة أو قائمة العقارات'
            },
            {
              question:
                'هل المعلومات المتعلقة بالعقارات المدرجة و التي يتم عرضها في الخريطة دقيقة ويمكن التعويل عليها ؟',
              answer:
                'لا تضمن الجهة المسئولة عن منصة وتطبيق هكتار صحة ودقة المعلومات المتاحة عن كل عقار مدرج في منصة وتطبيق هكتار وتكون مسؤلية المعلومات المتاحة على عاتق معلن العقار فقط لا غير'
            }
          ]
        },
        {
          title: 'مشاركة العقارات',
          icon: require('@/assets/img/svgs/Hectar icon/share.svg'),
          problems: [
            {
              question:
                'ماهي خيارات المشاركة المتاحة في منصة هكتار وتطبيقات هكتار للهواتف الذكية ؟',
              answer:
                'الخيارات المتاحة للمشاركة هي مشاركة تطبيقات هكتار للهواتف الذكية، أو مشاركة إعلان عقاري، أو مشاركة قائمة إعلانات عقارية لجهة عقارية  محددة'
            },
            {
              question: 'ماهي آليات أو طرق المشاركة المتاحة ؟',
              answer:
                'طرق وآليات مشاركة هي منصات وبرامج التواصل المتاحة في تطبيقات الهواتف الذكية ومنصات تطبيقات المتصفح'
            }
          ]
        },
        {
          title: 'الإعجاب بالعقارات',
          icon: require('@/assets/img/svgs/Hectar icon/like.svg'),
          problems: [
            {
              question:
                'ماهي خيارات الإعجاب المتاحة  في منصة هكتار وتطبيقات هكتار للهواتف الذكية ؟',
              answer:
                'خيارات الإعجاب المتاحة هي إبداء الأعجاب بإحدي الإعلانات العقارية المعلنة والمتاحة'
            },
            {
              question: 'ماهي آليات أو طرق الإعجاب المتاحة ؟',
              answer:
                'طرق وآليات الإعجاب المتاحة هي خاصية إبداء الإعجاب والتي تكون متاحة في عند عرض تفاصيل إعلان عقاري معين'
            }
          ]
        },
        {
          title: 'المفضلة',
          icon: require('@/assets/img/svgs/Hectar icon/save.svg'),
          problems: [
            {
              question:
                'ماهي خيارات التفضيل المتاحة  في منصة هكتار وتطبيقات هكتار للهواتف الذكية ؟',
              answer: 'خيارات التفضيل المتاحة هي تفضيل إعلان عقاري معين'
            },
            {
              question: 'ماهي آليات أو طرق التفضيل المتاحة ؟',
              answer:
                'طرق وآليات التفضيل المتاحة هي خاصية التفضيل والتي تكون متاحة في عند عرض تفاصيل إعلان عقاري معين'
            },
            {
              question:
                'كيف يمكن عرض الإعلانات العقارية التي تم تفضيلها من خلال خاصية التفضيل؟',
              answer:
                'يمكن عرض الإعلانات العقارية التي تم تفضيلها من خلال شاشة او صفحة مفضلتي مع إمكانية عرض الإعلانات العقارية التي تم تفضيلها و إلغاء التفضيل'
            }
          ]
        },
        {
          title: 'تقييم التجربة العقارية',
          icon: require('@/assets/img/svgs/Hectar icon/review.svg'),
          problems: [
            {
              question:
                'ماهي خيارات التقييم المتاحة  في منصة هكتار وتطبيقات هكتار للهواتف الذكية ؟',
              answer:
                'خيارات التقييم المتاحة هي خيار التقييم والذي يكون متاحا عند عرض إعلان عقاري معين'
            },
            {
              question: 'ماهي آليات أو طرق التقييم المتاحة ؟',
              answer:
                'طرق وآليات التقييم المتاحة هي خاصية تقييم التجربة العقارية والتي تكون متاحة عند عرض تفاصيل إعلان عقاري معين وتحتوى على خيارات متعددة تتيح التقييم بناءا على تجربة التفاعل التي تمت مع إعلان عقاري محدد'
            },
            {
              question:
                'هل يمكن إلغاء تقييم معين بعد إتمام عملية تقييم تجربة عقارية ؟',
              answer: 'لا يمكن إلغاء التقييم بعد إتمام علمية التقييم'
            },
            {
              question:
                'هل يمكن القيام بتقييم تجربة عقارية أكثر من مرة واحدة ؟',
              answer:
                'لا يمكن إجراء تقييم تجربة عقارية لإعلان عقاري واحد أكثر من مرة واحدة'
            }
          ]
        },
        {
          title: 'الرسائل',
          icon: require('@/assets/img/svgs/Hectar icon/message.svg'),
          problems: [
            {
              question: 'ماذا يعني عدم التمكن للدخول لحسابي بسبب وقف الحساب ؟',
              answer:
                'يعني ذلك وجود خطأ بسبب كتابة اسم المستخدم أو الرقم السري او وجود خطأ قانوني بعدم اتباع شروط واحكام وسياسات التسجيل، و في هذه الحالات يتم اغلاق الحساب لحين تصحيح اي من الاسباب المذكورة'
            },
            {
              question: 'كيف يمكنني تفعيل حسابي في حالة كان حسابي موقوفا ؟',
              answer:
                'يمكنك التواصل معنا عن طريق آلبة  والواتساب المتاحة في مركز المساعدة، وبعد التحقق والاستبيان من ملكية الحساب وعدم وجود اي متعلقات قانونية على الحساب سيتم إعادة فتح الحساب مرة اخري'
            }
          ]
        },
        {
          title: 'مركز المساعدة',
          icon: require('@/assets/img/svgs/Hectar icon/help.svg'),
          problems: [
            {
              question: 'ماذا يعني عدم التمكن للدخول لحسابي بسبب وقف الحساب ؟',
              answer:
                'يعني ذلك وجود خطأ بسبب كتابة اسم المستخدم أو الرقم السري او وجود خطأ قانوني بعدم اتباع شروط واحكام وسياسات التسجيل، و في هذه الحالات يتم اغلاق الحساب لحين تصحيح اي من الاسباب المذكورة'
            },
            {
              question: 'كيف يمكنني تفعيل حسابي في حالة كان حسابي موقوفا ؟',
              answer:
                'يمكنك التواصل معنا عن طريق آلبة  والواتساب المتاحة في مركز المساعدة، وبعد التحقق والاستبيان من ملكية الحساب وعدم وجود اي متعلقات قانونية على الحساب سيتم إعادة فتح الحساب مرة اخري'
            }
          ]
        }
      ],
      hectarPDeps: [
        {
          title: 'التسجيل وإدارة الملف الشخصي',
          icon: require('@/assets/img/svgs/Hectar icon/user 2.svg'),
          problems: [
            {
              question: 'كيف يمكن التسجيل في منصة هكتار بلس ؟',
              answer:
                'التسجيل في منصة هكتار بلس يكون من خلال خاصية التسجيل الجديد'
            },
            {
              question:
                'ماهي آلية التسجيل في منصة هكتار بلس في حال وجود حساب مسبق في منصة هكتار وتطبيقات   هكتار للهواتف الذكية ؟',
              answer:
                'بالإمكان استخدام  نفس معلومات التسجيل  في منصة هكتار وتطبيقات هكتار للهواتف الذكية للتسجيل في منصة هكتار بلس ولا يلزم ذلك التسجيل الجديد ويكون فقط تسجيل الدخول كافيا في منصة هكتار بلس وإكمال معلومات الشركه ثم بدء استخدام المنصة بكل سهولة'
            },
            {
              question:
                'ماهي آلية التسجيل في منصة هكتار بلس في حال لم يكن هناك حساب مسبق في منصة هكتار وتطبيقات هكتار للهواتف الذكية ؟',
              answer:
                'يعتبر التسجيل في منصة هكتار بلس تسجيلا ايضا في منصة هكتار و تطبيقات هكتار للهواتف الذكية وبالإمكان استخدام نفس معلومات الدخول لتسجيل الدخول فيهم جميعا'
            },
            {
              question:
                'هل يمكن القيام بتقييم تجربة عقارية أكثر من مرة واحدة ؟',
              answer:
                'لا يمكن إجراء تقييم تجربة عقارية لإعلان عقاري واحد أكثر من مرة واحدة'
            }
          ]
        },
        {
          title: 'العقارات',
          icon: require('@/assets/img/svgs/Hectar icon/house.svg'),
          problems: [
            {
              question: 'ماذا يعني عدم التمكن للدخول لحسابي بسبب وقف الحساب ؟',
              answer:
                'يعني ذلك وجود خطأ بسبب كتابة اسم المستخدم أو الرقم السري او وجود خطأ قانوني بعدم اتباع شروط واحكام وسياسات التسجيل، و في هذه الحالات يتم اغلاق الحساب لحين تصحيح اي من الاسباب المذكورة'
            },
            {
              question: 'كيف يمكنني تفعيل حسابي في حالة كان حسابي موقوفا ؟',
              answer:
                'يمكنك التواصل معنا عن طريق آلبة  والواتساب المتاحة في مركز المساعدة، وبعد التحقق والاستبيان من ملكية الحساب وعدم وجود اي متعلقات قانونية على الحساب سيتم إعادة فتح الحساب مرة اخري'
            }
          ]
        },
        {
          title: 'المزامنة مع هكتار',
          icon: require('@/assets/img/svgs/Hectar icon/synchronize.svg'),
          problems: [
            {
              question: 'ماهي خاصية المزامنة مع هكتار ؟',
              answer:
                'خاصية المزامنة مع هكتار تتيح إضافة وجلب الإعلانات العقارية المضافة  مسبقا في هكتار في حساب المستخدم لقائمة العقارات في هكتار بلس مع إمكانية  الإختيار المحدد والمتعدد للعقارات التي يود المستخدم اضافتها في هكتار بلس'
            },
            {
              question:
                'ماهي الخيارات المتاحة بعد إتمام عملية المزامنة مع هكتار ؟',
              answer:
                'هناك خيارات لإدارة العقارات التي تم إضافتها لحساب هكتار بلس من خلال خاصية المزامنة، منها إخفاء العقار من هكتار، إضافة عملاء ووثائق للعقار، وأيضا إضافة مسؤول عن العقار'
            }
          ]
        },
        {
          title: 'العملاء',
          icon: require('@/assets/img/svgs/Hectar icon/user 2.svg'),
          problems: [
            {
              question: 'ماذا يعني عدم التمكن للدخول لحسابي بسبب وقف الحساب ؟',
              answer:
                'يعني ذلك وجود خطأ بسبب كتابة اسم المستخدم أو الرقم السري او وجود خطأ قانوني بعدم اتباع شروط واحكام وسياسات التسجيل، و في هذه الحالات يتم اغلاق الحساب لحين تصحيح اي من الاسباب المذكورة'
            },
            {
              question: 'كيف يمكنني تفعيل حسابي في حالة كان حسابي موقوفا ؟',
              answer:
                'يمكنك التواصل معنا عن طريق آلبة  والواتساب المتاحة في مركز المساعدة، وبعد التحقق والاستبيان من ملكية الحساب وعدم وجود اي متعلقات قانونية على الحساب سيتم إعادة فتح الحساب مرة اخري'
            }
          ]
        },
        {
          title: 'إدارة الأملاك',
          icon: require('@/assets/img/svgs/Hectar icon/r-building.svg'),
          problems: [
            {
              question: 'ماذا يعني عدم التمكن للدخول لحسابي بسبب وقف الحساب ؟',
              answer:
                'يعني ذلك وجود خطأ بسبب كتابة اسم المستخدم أو الرقم السري او وجود خطأ قانوني بعدم اتباع شروط واحكام وسياسات التسجيل، و في هذه الحالات يتم اغلاق الحساب لحين تصحيح اي من الاسباب المذكورة'
            },
            {
              question: 'كيف يمكنني تفعيل حسابي في حالة كان حسابي موقوفا ؟',
              answer:
                'يمكنك التواصل معنا عن طريق آلبة  والواتساب المتاحة في مركز المساعدة، وبعد التحقق والاستبيان من ملكية الحساب وعدم وجود اي متعلقات قانونية على الحساب سيتم إعادة فتح الحساب مرة اخري'
            }
          ]
        },
        {
          title: 'إدارة التشغيل',
          icon: require('@/assets/img/svgs/Hectar icon/team.svg'),
          problems: [
            {
              question: 'ماذا يعني عدم التمكن للدخول لحسابي بسبب وقف الحساب ؟',
              answer:
                'يعني ذلك وجود خطأ بسبب كتابة اسم المستخدم أو الرقم السري او وجود خطأ قانوني بعدم اتباع شروط واحكام وسياسات التسجيل، و في هذه الحالات يتم اغلاق الحساب لحين تصحيح اي من الاسباب المذكورة'
            },
            {
              question: 'كيف يمكنني تفعيل حسابي في حالة كان حسابي موقوفا ؟',
              answer:
                'يمكنك التواصل معنا عن طريق آلبة  والواتساب المتاحة في مركز المساعدة، وبعد التحقق والاستبيان من ملكية الحساب وعدم وجود اي متعلقات قانونية على الحساب سيتم إعادة فتح الحساب مرة اخري'
            }
          ]
        },
        {
          title: 'الرسائل',
          icon: require('@/assets/img/svgs/Hectar icon/message.svg'),
          problems: [
            {
              question: 'ماذا يعني عدم التمكن للدخول لحسابي بسبب وقف الحساب ؟',
              answer:
                'يعني ذلك وجود خطأ بسبب كتابة اسم المستخدم أو الرقم السري او وجود خطأ قانوني بعدم اتباع شروط واحكام وسياسات التسجيل، و في هذه الحالات يتم اغلاق الحساب لحين تصحيح اي من الاسباب المذكورة'
            },
            {
              question: 'كيف يمكنني تفعيل حسابي في حالة كان حسابي موقوفا ؟',
              answer:
                'يمكنك التواصل معنا عن طريق آلبة  والواتساب المتاحة في مركز المساعدة، وبعد التحقق والاستبيان من ملكية الحساب وعدم وجود اي متعلقات قانونية على الحساب سيتم إعادة فتح الحساب مرة اخري'
            }
          ]
        },
        {
          title: 'مركز المساعدة',
          icon: require('@/assets/img/svgs/Hectar icon/help.svg'),
          problems: [
            {
              question: 'ماذا يعني عدم التمكن للدخول لحسابي بسبب وقف الحساب ؟',
              answer:
                'يعني ذلك وجود خطأ بسبب كتابة اسم المستخدم أو الرقم السري او وجود خطأ قانوني بعدم اتباع شروط واحكام وسياسات التسجيل، و في هذه الحالات يتم اغلاق الحساب لحين تصحيح اي من الاسباب المذكورة'
            },
            {
              question: 'كيف يمكنني تفعيل حسابي في حالة كان حسابي موقوفا ؟',
              answer:
                'يمكنك التواصل معنا عن طريق آلبة  والواتساب المتاحة في مركز المساعدة، وبعد التحقق والاستبيان من ملكية الحساب وعدم وجود اي متعلقات قانونية على الحساب سيتم إعادة فتح الحساب مرة اخري'
            }
          ]
        }
      ],
      userName: JSON.parse(localStorage.getItem('tokenData'))
        ? JSON.parse(localStorage.getItem('tokenData')).name
        : '',
      submit: false
    }
  },
  computed: {
    getRightTab() {
      return this.loggedIn === 'true' ? 1 : 0
    },
    firstName() {
      if (this.userName) {
        const name = this.userName
        return name.replace(/ .*/, '')
      }
      return null
    },
    breadcrumbs() {
      return [
        {
          text: 'مركز المساعده',
          disabeled: true
        }
      ]
    }
  },

  methods: {
    checkForm() {
      if (
        this.complain.hectarType &&
        this.complain.department &&
        this.complain.title &&
        this.complain.desc
      ) {
        return true
      }
      return null
    },
    startClick() {
      this.step = false
      this.typing = true
      this.counter = 0
      this.typingDots()
    },

    typingDots() {
      setTimeout(() => {
        this.typing = false
        this.counter++
        this.scrollToElement()
      }, 1000)
    },
    complainCreation(val, type) {
      if (type === 'hectarType') {
        this.complain.hectarType = val
        this.showComplainType = true
        this.departments = val === 'hectar' ? this.hectarDeps : this.hectarPDeps
      } else if (type === 'type') {
        this.complain.department = val.title
        this.showExistedComplains = true
        this.problems = val.problems.concat(this.problems)
      } else {
        this.answer = val.answer
        this.question = val.question
        this.startAgain = true
      }
      this.typing = true
      this.counterClick++
      this.typingDots()
      this.scrollToElement()
    },
    scrollToElement() {
      setTimeout(() => {
        const el = this.$el.querySelector('#step2')
        if (el) {
          el.lastElementChild.scrollIntoView({ behavior: 'smooth' })
        }
      }, 200)
    },
    resetComplain(state) {
      if (state) {
        this.showComplainType =
          this.showExistedComplains =
          this.startAgain =
            false
        this.complain = {}
        this.counterClick = 0
        this.typing = true
        this.typingDots()
        this.counter = 0
        this.answer = this.question = ''
      } else {
        this.showExistedComplains = this.startAgain = false
        this.complain.department = ''
        this.counterClick = 1
        this.typing = true
        this.typingDots()
        this.counter = 1
        this.answer = this.question = ''
      }
      this.submit = false
      this.inputState = false
    },
    backToStartPage() {
      this.resetComplain(true)
      this.step = true
      this.firstTime = false
      this.formCheck = false
    },
    contact(str) {
      if (this.getTime()) {
        if (str === 'whatsapp') {
          return window.open(
            `https://api.whatsapp.com/send?phone=${this.hectarInternationalPhoneNumber}&text= كيف نقدر نخدمك - ${this.userName} مرحباً`,
            '_blank'
          )
        } else if (str === 'phone') {
          return window.open(
            `tel:+${this.hectarInternationalPhoneNumber}`,
            '_blank'
          )
        }
      }
      return null
    },
    getTime() {
      const currentTime = new Date()
      if (
        currentTime.getHours() >= 9 &&
        currentTime.getHours() <= 16 &&
        currentTime.getMinutes() <= 59
      ) {
        this.isDisbled = true
      }
      return this.isDisbled
    },
    onSubmit() {
      if (this.checkForm()) {
        this.submit = true
        this.inputState = true
        this.scrollToElement()
      } else {
        this.formCheck = true
      }
    },
    loginPageRoute() {
      this.$router.push('/auth/login')
    }
  },
  created() {
    this.loggedIn = localStorage.getItem('logged_in')
    this.rightTabIndex = this.loggedIn === 'true' ? 1 : 0
    this.getTime()
  }
}
</script>

<style lang="scss">
@import '@/styles/helpCenter/helpCenter.scss';
</style>
